import React from 'react';
import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/cn.css';

loadCSS('cn-site');

export const CnSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default CnSiteWrapper;
